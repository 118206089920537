import React from "react";
import { ULYSSEUS_INSTITUTIONS_DATA } from "../../config/metadata";

const ProfileInstitution = ({ name }) => {
	let indexOfinstitution = ULYSSEUS_INSTITUTIONS_DATA.findIndex(
		(obj) => obj.name === name,
	);
	let metadata = ULYSSEUS_INSTITUTIONS_DATA[indexOfinstitution];

	return (
		<>
			{metadata && (
				<div id="profile-institution">
					<div className="container">
						<div className="section-left">
							<a
								className="ancre"
								href={metadata?.link && metadata.link}
								target="_blank"
							>
								{metadata?.imgPath && (
									<img
										src={metadata.imgPath}
										alt={metadata.name}
									/>
								)}
								<p className="website-link ui icon button">
									Website
								</p>
							</a>
						</div>
						<div className="section-right">
							{metadata?.name && (
								<p className="text">{metadata?.name}</p>
							)}
							{metadata?.cityCountry && (
								<p className="metadata-title">
									City and country:
									<span className="up2">
										{metadata.cityCountry}
									</span>
								</p>
							)}

							{metadata?.Students && (
								<p className="metadata-title">
									Number of students:
									<span className="up2">
										{metadata.Students}
									</span>
								</p>
							)}

							{metadata?.RessearchGroups && (
								<p className="metadata-title">
									Number of research groups:
									<span className="up2">
										{metadata.RessearchGroups}
									</span>
								</p>
							)}

							{metadata?.facultiesAndSchools && (
								<p className="metadata-title">
									Number of faculties and schools:
									<span className="up2">
										{metadata.facultiesAndSchools}
									</span>
								</p>
							)}

							{metadata?.founded && (
								<p className="metadata-title">
									Founded:
									<span className="up2">
										{metadata.founded}
									</span>
								</p>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ProfileInstitution;
