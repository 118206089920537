import React from "react";

const NoRecord = () => {
	return (
		<div id="no-record">
			<img src="static/images/frontpage/logo-utils/empty-data.svg" />
			<p>No publications collected so far.</p>
		</div>
	);
};

export default NoRecord;
