import React from "react";

const TabButton = ({ tab, currentTab, setCurrentTab, index }) => {
	const isTabActive =
		currentTab === tab.id || (currentTab === 6 && tab.id === 1);

	return (
		<button
			className={`tab ${isTabActive ? "active" : ""}`}
			key={tab.id}
			id={tab.id}
			onClick={() => {
				setCurrentTab(index);
			}}
		>
			<p className="text">{tab.name.toUpperCase()}</p>
			<i className={`ui icon svg ${tab.imgPath}`}></i>
		</button>
	);
};

export default TabButton;
