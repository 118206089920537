import React from "react";

function WelcomeSection() {
	return (
		<div
			id="get-started-section"
			className="get-started-section section-content"
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				textAlign: "center",
			}}
		>
			<h2 className="section-title">
				Welcome to Ulysseus Open-Science Repository!
				<br />
				What is ahead?
			</h2>

			<div className="ui container three column stackable grid center aligned vertically padded relaxed">
				<div className="rdm-goal column">
					<div className="featured-list">1</div>
					<h3 className="section-title">Discover</h3>
					<div className="command-line">
						In this repository, you will be able to search and
						discover open science that is produced within Ulysseus
						European University. Please, use the search field above
						and discover all existing publications.
					</div>
				</div>
				<div className="rdm-goal column">
					<div className="featured-list">2</div>
					<h3 className="section-title">Publish</h3>
					<div className="command-line">
						If you are a researcher from Ulysseus who wishes to
						publish your work as open science while following FAIR
						accessibility principles, then this is your site. If you
						wish to publish scientific data or a piece of software,
						then just create a new record and upload your
						contribution.
					</div>
				</div>
				<div className="rdm-goal column">
					<div className="featured-list">3</div>
					<h3 className="section-title">Join our innovation hubs</h3>
					<div className="command-line">
						Thanks to the concept of communities, you can now
						publish your work within one of our Innovation Hubs and
						obtain further reach. Check out our communities here.
					</div>
				</div>
			</div>
		</div>
	);
}

export default WelcomeSection;
