import React from "react";

import { ULYSSEUS_INSTITUTIONS_DATA } from "../../config/metadata";

const Institutions = ({ setCurrentTab, setWichone }) => {
	function handleClick(item) {
		setCurrentTab(6);
		setWichone(item);
	}

	return (
		<>
			<p className="title">Member Institutions</p>
			<div className="institutions-content">
				{ULYSSEUS_INSTITUTIONS_DATA.map((item, index) => {
					return (
						<button
							key={index}
							className="institution"
							onClick={() => {
								handleClick(item);
							}}
						>
							<p>{item.name}</p>
							<img src={item.imgPath} alt={item.name} />
						</button>
					);
				})}
			</div>
		</>
	);
};

export default Institutions;
