import React, { useState, useEffect } from "react";
import Record from "./Record";
import ProfileInstitution from "./ProfileInstitution";
import Loading from "./Loading";
import NoRecord from "./NoRecord";
const ReUpUniv = ({ wichone, setCurrentTab }) => {
	const [count, setCount] = useState(null);

	useEffect(() => {
		let url = `/${wichone.shortName}/records`;

		fetch(url)
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
			})
			.then((data) => {
				setCount(data);
			});
	}, []);

	return (
		<div className="recent-post-institution">
			<div className="back-button-div">
				<button
					className="ui icon button back-button-element"
					onClick={() => {
						setCurrentTab(1);
					}}
				>
					<i class="chevron left icon"></i>
					<p className="btn-back-text">Back to all institutions</p>
				</button>
			</div>
			<ProfileInstitution name={wichone.name} />

			{count === null && <Loading />}
			{count !== null && count.length === 0 && <NoRecord />}
			{count !== null && count.length !== 0 && (
				<>
					<div className="ui divided link relaxed items">
						{count.map((item, index) => {
							return <Record key={index} record={item} />;
						})}
					</div>

					<div className="search-origin-repository">
						<a
							href={`/search?q=custom_fields.origin_repository:"${wichone?.shortName}"`}
						>
							<button className="ui icon button">More</button>
						</a>
					</div>
				</>
			)}
		</div>
	);
};

export default ReUpUniv;
