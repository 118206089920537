import React from "react";

const Loading = () => {
	return (
		<div id="loading-frontpage">
			<div className="dot-spinner">
				<div className="dot-spinner__dot"></div>
				<div className="dot-spinner__dot"></div>
				<div className="dot-spinner__dot"></div>
				<div className="dot-spinner__dot"></div>
				<div className="dot-spinner__dot"></div>
				<div className="dot-spinner__dot"></div>
				<div className="dot-spinner__dot"></div>
				<div className="dot-spinner__dot"></div>
			</div>
			<p className="text">Record recovery in progress, please wait</p>
		</div>
	);
};

export default Loading;
