import React from "react";

const Record = ({ record }) => {
	return (
		<div className="item">
			<div className="content">
				<div className="extra labels-actions">
					<div className="ui tiny label primary">
						{record.ui.created_date_l10n_long}
					</div>
					<div className="ui tiny label neutral">Publication</div>
					<div className="ui tiny label access-status metadata-only">
						<i className="icon tag"></i>Metadata-only
					</div>
				</div>
				<h2 className="header">
					<a href={`/records/${record.id}`}>
						{record.metadata.title}
					</a>
				</h2>
				<div className="item creatibutors">
					{record.ui.creators.creators.map((contributor, index) => {
						return (
							<span
								className="creatibutor-wrap separated"
								key={index}
							>
								<a
									className="creatibutor-link"
									href={`/profile?q=${contributor.person_or_org.name}`}
									title={`${contributor.person_or_org.name}: Search`}
								>
									<span className="creatibutor-name">
										{contributor.person_or_org.name}
									</span>
								</a>
							</span>
						);
					})}
				</div>
				<div className="description short-description">
					{record.ui.description_stripped}
				</div>
				<div className="extra">
					<div>
						<small>
							Uploaded on{" "}
							<span>{record.ui.updated_date_l10n_long}</span>
						</small>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Record;
